class StickyHeader extends HTMLElement {
    connectedCallback() {
        if (this.getAttribute('is')) {
            this.removeAttribute('is');

            let headerFixedObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (!entry.isIntersecting) {
                        this.classList.add('fixed');
                    } else {
                        this.classList.remove('fixed');
                    }
                });
            });
            headerFixedObserver.observe(document.querySelector('.intersect-header'));
        }
    }

    disconnectedCallback() {
        if (this.getAttribute('is')) {
        }
    }
}

window.customElements.define('sticky-header', StickyHeader, {extends: 'header'});
