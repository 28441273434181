import Swiper from 'swiper/swiper-bundle.min';
import './slider.css';

class Slider extends HTMLElement {
    connectedCallback() {
        const options =  {
            loop: true,
            autoplay: {
                delay: this.dataset.autoplayDelay ?? 5000,
            },
            autoHeight: true,
            lazy: {
                loadPrevNext: true,
            },
            slidesPerView: 'auto',
        };

        if (this.dataset.slidesPerView) {
            options.slidesPerView = this.dataset.slidesPerView;
        } else {
            options.slidesPerView = 1;
            options.breakpoints = {
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                }
            };
        }
        const swiper = new Swiper(this.querySelector('.swiper'),options);
    }
}

window.customElements.define('tchatcha-slider', Slider);
