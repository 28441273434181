import './app.scss';

// Polyfill
import '@ungap/custom-elements-builtin';

// Components
import '../shared/components/sticky-header/sticky-header';
import '../shared/components/scroll-to-top/scroll-to-top';
import '../shared/components/slider/slider';

import './images/preview/1.png';
import './images/preview/2.png';
import './images/preview/3.png';

// events
import './images/events/bayonne.jpeg'
import './images/events/dax.jpg'
import './images/events/pau.jpeg'
import './images/events/supersev.jpeg'

// partners
import './images/partners/cgr.png'
import './images/partners/dax.png'
import './images/partners/pau.jpg'
import './images/partners/sensiroute.jpg'
import './images/partners/helioparc.png'
import './images/partners/laulhere.jpg'
import './images/partners/next.png'
import './images/partners/npy.png'
import './images/partners/section.png'
import './images/partners/fairplayforplanet.png'
import './images/partners/totalenergies.png'