class ScrollToTop extends HTMLDivElement {
    connectedCallback() {
        if (this.getAttribute('is')) {
            this.removeAttribute('is');

            this.addEventListener('click', (e) => {
                e.preventDefault();
                window.scrollTo({top: 0, behavior: "smooth"});
            })
        }
    }

    disconnectedCallback() {
        if (this.getAttribute('is')) {
        }
    }
}

window.customElements.define('scroll-to-top', ScrollToTop, {extends: 'div'});
